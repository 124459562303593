.full-page-loader {
  background: #fff;
  position: fixed;
  z-index: 999;
  overflow: hidden;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-spinner {
  animation-duration: 1.3s;
  animation-iteration-count: infinite;
  height: 200px;
  margin: 0 auto 0 auto;
  transform-origin: bottom;
  width: 200px;
}

.bounce {
  animation-name: jumping;
  animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
}

@keyframes jumping {
  0%   { transform: scale(1,1)      translateY(0); }
  25%  { transform: scale(1.1,.9)   translateY(0); }
  50%  { transform: scale(.9,1.1)   translateY(-100px); }
  75%  { transform: scale(1.05,.95) translateY(0); }
  100%  { transform: scale(1,1)      translateY(-7px); }
  //64%  { transform: scale(1,1)      translateY(0); }
  //100% { transform: scale(1,1)      translateY(0); }
}
@import "~bootstrap/scss/bootstrap";

@font-face {
  font-family: "Hey Comic";
  src: url("assets/fonts/hey-comic.ttf") format("truetype");
}

body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  font-family: 'Hey Comic';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  display: inline-block;
}

.panel {
  background-color:#fff;
  border:solid 2px #000;
  box-shadow: 8px 8px 8px -8px #000;
  display:inline-block;
  margin: 1vmin;
  overflow: hidden;
  position:relative;
  transition: all 0.1s;
}

.panel img {
  transition: all 0.3s;
}

.panel:hover {
  box-shadow: 10px 10px 15px -8px #000;
  img {
    transform: scale(1.1);
  }
}

.text {
  background-color: #fff;
  border: solid 2px #000;
  margin: 0;
  padding: 3px 10px;
  position: absolute;
  transform: skew(-15deg);
}

.top-left {
  top: -2px;
  left: -10px;
  padding-left: 15px;
}

.top-right {
  top: -2px;
  right: -10px;
  padding-right: 15px;
}

.bottom-left {
  bottom: -2px;
  left: -10px;
  padding-left: 15px;
}

.bottom-right {
  bottom: -2px;
  right: -10px;
  padding-right: 15px;
}

.speech {
  background-color:#fff;
  border:solid 2px #000;
  border-radius:12px;
  display:inline-block;
  margin:.5em;
  padding:.5em 1em;
  position:relative;
}

.speech:before {
  border:solid 12px transparent;
  border-left:solid 12px #000;
  border-top:solid 12px #000;
  bottom:-24px;
  content:"";
  height:0;
  left:24px;
  position:absolute;
  transform:skew(-15deg);
  width:0;
}

.speech:after {
  border:solid 10px transparent;
  border-left:solid 10px #fff;
  border-top:solid 10px #fff;
  bottom:-19px;
  content:"";
  height:0;
  left:27px;
  position:absolute;
  transform:skew(-15deg);
  width:0;
}

/* background colours */

/*.panel:nth-child(4n+1) {
  background-image:radial-gradient(circle, yellow, orange);
}

.panel:nth-child(4n+2) {
  background-image:radial-gradient(circle, lightblue, deepskyblue);
}

.panel:nth-child(4n+3) {
  background-image:radial-gradient(circle, palegreen, yellowgreen);
}

.panel:nth-child(4n+4) {
  background-image:radial-gradient(circle, lightcoral, tomato);
}*/

.comic-background-yellow {
  background: radial-gradient(circle, yellow, orange) !important;
}

.comic-background-blue {
  background: radial-gradient(circle, lightblue, deepskyblue) !important;
}

.comic-background-green {
  background: radial-gradient(circle, palegreen, yellowgreen) !important;
}

.comic-background-red {
  background: radial-gradient(circle, lightcoral, tomato) !important;
}

.section-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.comic-button {
  //height: 50px;
  padding: 5px 20px;
  margin: 5px 10px;
  font-size: 24px;
  font-weight: bold;
  color: #000;
  background: radial-gradient(circle, yellow, orange);
  border: 2px solid black;
  box-shadow: 5px 5px 0 black;
  /*-5px -5px 0 black,
  -5px 5px 0 black,
  5px -5px 0 black; */
  transition: 100ms ease-in-out;
  -ms-transform: skewX(-20deg);
  -webkit-transform: skewX(-20deg);
  transform: skewX(-20deg);

  &:hover {
    box-shadow: 10px 8px 0 black;
    color: #000;
  }

  &:active {
    transform: skewX(-20deg) translateY(4px);
    box-shadow: 6px 4px 0 black;
  }

  &:focus {
    outline: none;
  }

  .comic-button-text {
    -ms-transform: skewX(20deg);
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    display: inline-block;
  }
}

.copyright-label {
  font-family: 'Open Sans';
  color: gray;
}

